:root {
    /* Light theme colors */
    --primary-color-light: #007bff;
    --background-color-light: #f8f9fa;
    --text-color-light: #212529;
    --message-background-light: #e2e3e5;

    /* Dark theme colors */
    --primary-color-dark: #0d6efd;
    --background-color-dark: #343a40;
    --text-color-dark: #f8f9fa;
    --message-background-dark: #e2e3e5;

    --gray: #adb5bd;
}

.light-theme {
    --primary-color: var(--primary-color-light);
    --background-color: #f8f9fa;
    --text-color: var(--text-color-light);
    --message-background: var(--message-background-light);
}

.dark-theme {
    --primary-color: var(--primary-color-dark);
    --background-color: var(--background-color-dark);
    --text-color: var(--text-color-dark);
    --message-background: var(--message-background-dark);
}

.chatApp {
    background-color: #f8f9fa;
    color: var(--text-color);
    min-height:100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: border-box;
}

.toggleTheme{
    margin-left:auto;
}

@media (min-width: 768px) {
    .toggleTheme{
        display:none;
    }
}

.chatHeader{
    height:100px;
    border-bottom:1px solid var(--gray);
    font-size:16px;
    font-weight:bold;
    display: flex;
    align-items: center;
    padding-left:30px;
    padding-right:30px;
    box-sizing: border-box;
    gap:10px;
    position:fixed;
    z-index:100;
    background-color: #f8f9fa;
    width:70vw;
    right:0;
}

.chatContent{
    padding:20px;
    padding-top:120px;
    padding-bottom:70px;
    box-sizing: border-box;
    width:70vw;
    display:flex;
    flex-direction:column;
    min-height:100vh;
    justify-content: flex-end;
}

.chatContainer{
    display:flex;
    flex-direction:row;
    width:100%;
    box-sizing: border-box;
    background-color: #f8f9fa;
}



.panelContainer{
    width:30vw;
    background: #f8f9fa;
    border-right:1px solid var(--gray);
    box-sizing: border-box;
    position:relative;
}
body{
    margin:0px;
}

.panelContents{
    position:fixed;
    top:0;
    left:0;
    width:30vw;
    box-sizing: border-box;
    height:100vh;
    display: flex;
    flex-direction: column;
}

.modelListDiv{
    overflow-y:scroll;
    overflow-x:hidden;
}

.modelListDiv::-webkit-scrollbar { display: none; }



.message{
    background-color:var(--message-background);
    width:fit-content;
    margin-bottom:10px;
    padding-left:20px;
    padding-right:20px;
    border-radius:25px;
    max-width:70%;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.imageContainer{
    height:300px;
    position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:-20px;
    margin-bottom:-20px;
}

.inputArea{
    display:flex;
    gap:20px;
    width:70vw;
    background-color:white;
    padding:20px;
    box-sizing:border-box;
    border-radius:0px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    position:fixed;
    bottom:0;
    right:0;
}

.submitlogo{
    opacity:0.7;
    margin-right:10px;
    cursor:pointer;
    transform: 0.2s;
}

.submitlogo:hover{
    opacity:1;
}

.inputField{
    width:100%;
    background:white;
    border:none;
    outline:none;
}


.loader {
    width: 24px;
    height: 24px;
    border: 5px solid;
    border-color: #ba00a8 transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
  
  .modelProfile{
    align-self: center;
    margin-bottom:auto;
    padding-bottom:20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:10px;
    cursor:pointer;
    user-select: none;
}

.modelName{
    font-size:20px;
    font-weight:bold;
    opacity:0.9;
}

.followerCount{
    opacity:0.7;
}

.login{
    width:100%;
    height:100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color:black;
    font-weight:600;
    cursor:pointer;
}

audio{
    max-width: 80vw;
}

.modal{
    color:white;
}

.photo-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Creates 3 columns */
    grid-gap: 10px; /* Adjust the gap between grid items */
    max-width: 50vh; /* Adjust based on your requirement */
    margin: auto; /* Center the grid */
}

.photo-item {
    width: 100%;
    object-fit: cover; /* Ensures the images cover the grid item */
    background-size: cover;
    background-position: center;
    border-radius:5px;
}


.loading {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding-top:20px;
    padding-bottom:20px;
    box-sizing: border-box;

  }
  
  .loading__dot {
    animation: dot ease-in-out 1s infinite;
    background-color: rgb(175, 175, 175);
    display: inline-block;
    height: 5px;
    margin: 3px;
    width: 5px;
    border-radius:2px;
  }
  
  .loading__dot:nth-of-type(2) {
    animation-delay: 0.2s;
  }
  
  .loading__dot:nth-of-type(3) {
    animation-delay: 0.3s;
  }
  
  @keyframes dot {
    0% { background-color: grey; transform: scale(1); }
    50% { background-color: #ffffff; transform: scale(1.3); }
    100% { background-color: grey; transform: scale(1); }
  }

  .bottomRef{
    position:relative;
    bottom:-50px;
  }


  @media (max-width: 768px) {
    .panelContainer{
        display:none;
    }
    .chatContent{
        width:100vw;
    }
    .inputArea{
        width:100vw;
    }
    .message{
        max-width:70vw;
    }
    .chatHeader{
        width:100vw;
    }
}

@media screen and (min-width: 768px) {
    .message{
        font-size:14px;
    }
    
}

.hovernoselect{
    user-select: none;
    cursor:pointer;
}

.menuChatCard{
    width: 90%;
    display: flex;
    flex-direction: row;
    padding:20px 30px 20px 30px;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    gap:20px;
    cursor:pointer;
    transition:0.3s;
    position:relative;

}

.logout{
    width:100%;
    text-align:center;
    cursor:pointer;

}

.drawerBottom{
    margin-top:auto;
    margin-bottom:30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:20px;
    font-weight:600;
    font-size:14px;
}

.drawerBottomDesktop{
    margin-top:auto;
    margin-bottom:30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap:wrap;
    gap:20px;
    font-weight:600;
    font-size:14px;
    color:rgba(0, 0, 0, 0.341);
    width:100%;
    padding-left:30px;
    padding-right:30px;
    box-sizing: border-box;
}

.drawerBottomDesktopButton{
    font-weight:600;
    font-size:13px;
    cursor:pointer;
    color:black;
}

.menuChatCard:hover{
    margin-left:20px;
}

.panelTitle{
    font-size:20px;
    font-weight:bold;
    opacity:0.8;
    padding:30px;
    width:100%;
    box-sizing: border-box;
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-between
}

.brandChat{
    color:black;
    font-weight:900;
    font-size:24px;
    letter-spacing: -1px;
    padding:30px 30px 0px 30px;
    width:100%;
  }

.creditAmount{
    font-size:14px;
    font-weight:500;
    margin-right:30px;
    cursor:pointer;

}

.creditAmount:hover span{
    display:none;
}

.creditAmount:hover:before{
    content:"Get more!";
    color: #ff00d0;
    font-weight:600;
    transition:0.2s;
    cursor:pointer;
}

.menuChatCardName{
    font-size:14px;
    font-weight:500;
    opacity:0.8;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap:5px;
}

.menuChatCardDescription{
    font-size:12px;
    opacity:0.7;
}


.drawer{
    width:100vw;
}

.buyCredits{
    text-decoration:none;
    color:white;
    padding:10px;
    border-radius:20px;
    transition:0.2s;
    background:rgb(255, 0, 247);
    font-weight:500;
    cursor:pointer;
    font-size:14px;
    opacity:0.7;
}

.buyCredits:hover{
    opacity:1;
}


.scrollToBottom{
    position:fixed;
    bottom:80px;
    right:auto;
    z-index:200;
    background:#007bffd2;
    border-radius: 100px;
    padding:5px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:row;
    gap:10px;
    color:white;
    font-weight:500;
    cursor:pointer;
}

.improveimage{
    position:absolute;
    top:20px;
    right:15px;
    color:white;
    font-weight:500;
    font-size:12px;
    background: #ff00d0;
    padding:3px 6px;
    border-radius:3px;
    cursor:pointer;
}

.getNotified{
    cursor:pointer;
    font-size:14px;
    color:white;
    background:#ba00a792;
    padding: 5px 10px;
    border-radius:5px;
    transition:0.2s;
    text-decoration: none;
}

.header-right{
    margin-left:auto;
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    gap:20px;
}

.getNotified-mobile{
    cursor:pointer;
    font-size:14px;
    color:white;
    background:#ba00a792;
    padding: 5px 10px;
    border-radius:5px;
    transition:0.2s;
    text-align:center;
    margin-top:10px;
}

.instagramFollow{
    cursor:pointer;
    font-size:14px;
    color:white;
    background:#ba00a792;
    padding: 5px 10px;
    border-radius:5px;
    transition:0.2s;
    text-align:center;
    margin-top:10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;
    text-decoration: none;
}

.instagramFollow:hover{
    background:#ba00a7;
}

.getNotified:hover{
    background:#ba00a7;
}

@media (max-width: 768px) {
    .header-right{
        display:none;
    }
}


.oneclicksignup{
    text-align: center;
    font-size:18px;
    color:white;
    font-weight:600;
    text-shadow: 
    2px 2px 5px #0000005b, 
    -2px -2px 5px #0000005b, 
    2px -2px 5px #0000005b, 
    -2px 2px 5px #0000005b;
}

.accepttos{
    text-align: center;
    font-size:14px;
    color:white;
    font-weight:400;
    margin-top:20px;
    margin-bottom:20px;
    text-shadow: 
    2px 2px 5px #0000005b, 
    -2px -2px 5px #0000005b, 
    2px -2px 5px #0000005b, 
    -2px 2px 5px #0000005b;
}

.googleLogin{
    text-decoration:none;
    color:white;
    padding:10px 20px;
    border-radius:5px;
    transition:0.2s;
    background:rgba(255, 0, 247, 0.64);
    font-weight:500;
    cursor:pointer;
    font-size:14px;
    text-align: center;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
}

.googleLogin:hover{
    background:#ff00f7;
}

.tosmodalcontent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.upscaledimage{
    background-color: rgba(0, 0, 0, 0.822);
    display: flex;
    align-items: center;
    justify-content: center;
    height:80%;
    width:250px;
    border-radius:20px;
    color:white;
    font-weight:600;
    font-size:14px;
    text-align:center;
    cursor:pointer;
    transition:0.2s;
}

.upscaledimage:hover{
    background-color: rgba(0, 0, 0, 0.9);
}


.feedbackInput{
    border:none;
    outline:none;
    border-radius:10px;
    padding:10px;
    max-width:50vw;
    max-height:50vh;
    min-width:50vw;
}

@media screen and (max-width: 768px) {
    .feedbackInput{
        max-width:90vw;
        min-width:90vw;
    }
}

.feedbackTitle{
    font-size:20px;
    font-weight:bold;
    margin-bottom:10px;
}

.feedbackButton{
    padding:20px;
    width:100%;
    margin-top:20px;
    background:rgba(255, 0, 189, 0.7);
    border-radius:10px;
    color:white;
    border:none;
    font-weight:500;
    cursor: pointer;
    transition:0.2s;
}

.feedbackButton:hover{
    background:rgba(255, 0, 189, 0.9);
}

.sponsorWrapper2{
    width:100%;
    text-align:center;
    margin-top:40px;
    display: flex;
    flex-wrap:wrap;
    align-items: center;
    justify-content: center;
    gap:20px;
  }
  


  .solanaModal{
    text-align:center
  }