.text{
    position:absolute;
    bottom:80px;
    left:20px;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.629);
    font-weight:500;   
}

.text-small{
    position:absolute;
    bottom:60px;
    left:20px;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.43);
    font-weight:400;  
    font-size:12px; 
}

.heart{
    position:absolute;
    top:50vh;
    right:20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size:12px;
    opacity:0.8;

}

.share{
    position:absolute;
    top:60vh;
    right:20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size:12px;
    opacity:0.8;
}