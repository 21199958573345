.Workspace {
    text-align: center;
    width:100%;
    height:100vh;
    background:black;
  }
  
  .workspaceinput {
    margin: 10px;
    padding: 8px;
  }
  
  .workspacebutton {
    padding: 10px;
    background-color: blue;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .workspacebutton:disabled {
    background-color: grey;
  }
  
  .workspaceimg {
    max-width: 100%;
    height: auto;
    margin-top: 20px;
  }
  